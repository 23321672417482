<template>
<div class="contact-page">
    <div class="post-image" style="background: url('http://printek.top-it72.ru/wp-content/uploads/2021/02/shirokoformat.jpg') center center / cover no-repeat fixed;">
        <div class="slide-overlay post-overlay">
            <div class="post-title">Вакансии</div>
        </div>
    </div>
    <section>
        <div class="container" v-html="this.$store.state.vacancy.content.rendered"></div>
    </section>
    <Contacts />
</div>
</template>

<script>
import Contacts from '../components/Contacts.vue'
export default {
    components: { Contacts },
    mounted() {
        document.title = 'Вакансии | UVprint'
        scrollTo(0, 0)

    },
}
</script>

<style>

</style>
