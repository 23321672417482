<template>
<div class="container" style="align-items:start">
    <h3>Карта проезда</h3>
    <h4>{{adres}}</h4>
    <div id="map2" style="width:100%; height:400px">
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            adres: this.$store.state.contacts.acf.adres
        }
    },
    methods: {
        init() {
            var DG = require('2gis-maps');
            var
                map = DG.map('map2', {
                    'center': [57.131748, 65.518627],
                    'zoom': 16
                });
            DG.marker([57.131748, 65.518627]).addTo(map);
            DG.popup([57.131748, 65.518627])
                .setLatLng([57.131748, 65.518627])
                .setContent('<h4>UVprint - рекламная фирма</h4>')
                .openOn(map);

        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style>
.dg-popup__container h4 {
    text-align: center;

}
</style>
