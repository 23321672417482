<template>
<div class="contact-page">
    <div class="post-image" style="background: url('http://printek.top-it72.ru/wp-content/uploads/2021/02/shirokoformat.jpg') center center / cover no-repeat fixed;">
        <div class="slide-overlay post-overlay">
            <div class="post-title">Контакты</div>
            <div class="post-description-c">Мы всегда рады вашим звонкам и визитам!</div>
        </div>
    </div>
    <section>
        <div class="container">
            <div class="cp-wrapper">
                <div class="cp-holder">
                    <div class="cp-icon"><v-icon>mdi-phone-in-talk-outline</v-icon></div>
                    <div class="cp-text-bold">Телефон</div>
                    <div class="cp-text">{{acf.telefon}}</div>
                </div>
                <div class="cp-holder">
                    <div class="cp-icon"><v-icon>mdi-map-marker-outline</v-icon></div>
                    <div class="cp-text-bold">Адрес нашего офиса</div>
                    <div class="cp-text">{{acf.adres}}</div>
                </div>
                <div class="cp-holder">
                    <div class="cp-icon"><v-icon>mdi-clock-time-eight-outline</v-icon></div>
                    <div class="cp-text-bold">Часы работы</div>
                    <div class="cp-text">{{acf.chasy_raboty}}</div>
                </div>
            </div>
        </div>
    </section>
    <section>
        
            <Map />
        
    </section>
    <section v-if="sotrud[0]">
        <div class="container">
            
            <h3>Наши сотрудники</h3>
            <div class="sotrs" v-for="(otd,index) in sotrud" :key="index">

                <h4>{{otd.nazvanie_otdela}}</h4>
                <div class="sotr-holder">
                    <div v-for="(sotr,index) in otd.sotrudnik" :key="index" class="sotr-wrapper">
                        <div class="sotr-img" :style="'background: url(\''+sotr.foto.sizes.medium+'\') no-repeat center center / cover'"></div>
                        <div class="sotr-data">
                            <p class="sotr-name">{{sotr.imya}}</p>
                            <div v-if="sotr.telefon">
                                <p v-for="(numb, index) in sotr.telefon.split(';')" :key="index" class="sotr-phone">
                                    <v-icon>mdi-phone-in-talk-outline</v-icon>{{numb}}
                                </p>
                            </div>
                            <p class="sotr-mail">
                                <v-icon>mdi-email-edit-outline</v-icon> {{sotr.pochta}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <contacts />
</div>
</template>

<script>

import Contacts from '../components/Contacts.vue'
import Map from '../components/Map.vue'
export default {
    components: { Contacts, Map },
    data() {
        return {
            sotrud: this.$store.state.contacts.acf.nashi_sotrudniki,
            acf: this.$store.state.contacts.acf
            
        }
    },
    mounted() {
        document.title = 'Контакты | UVprint'
        scrollTo(0, 0)
        
    },

}
</script>

<style>
.cp-text-bold{
    font-weight: 500;
    font-size: 20px;
}
.cp-icon .v-icon{
    font-size: 50px;
}
.cp-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.cp-holder{
    flex: 1 1 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}
.sotrs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    width: 100%;
}

.sotr-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.sotr-img {
    height: 170px;
    width: 170px;
    background-color: grey;
    border-radius: 50%;
}

.sotr-wrapper {
    display: flex;
    flex: 1 1 300px;
    transition: all .3s;
    padding: 20px;
    
}
.sotrs h4{
    font-size: 22px;
}
.contact-page h3{
    width: 100%;
    text-align: left;
}
.sotr-wrapper:hover{
    background-color: #f9f9f9;
}

.sotr-name {
    font-size: 20px;
    font-weight: 500;
}
.sotr-data{
    margin-left: 20px;
}
.post-description-c{
    color: white;
    font-size: 22px;
    text-align: center;
}
</style>
