<template>
<div class="contact-page">
    <div class="post-image" style="background: url('http://printek.top-it72.ru/wp-content/uploads/2021/02/shirokoformat.jpg') center center / cover no-repeat fixed;">
        <div class="slide-overlay post-overlay">
            <div class="post-title">Требования к макету</div>
            
        </div>
    </div>
    <section>
        <div class="container" v-html="this.$store.state.maket.content.rendered">
            
        </div>
    </section>
    <Contacts />
</div>
</template>

<script>
import Contacts from '../components/Contacts.vue'
export default {
  components: { Contacts },
mounted() {
        document.title = 'Требования к макету | UVprint'
        scrollTo(0, 0)
        
    },
}
</script>

<style>

.blocks-gallery-item img{
    width: auto;
    height: auto;
}
.wp-block-table tr:nth-child(odd){
    background-color: rgba(224, 224, 224, 0.521);
}
.wp-block-table td{
    padding: 4px 10px;
}
.wp-block-table td:first-child{
    width: 200px;
}
</style>
