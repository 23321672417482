<template>
<div class="main">
    <div class="post-image" :style="'background: url(\''+acf.glavnoe_izobrazhenie+'\') no-repeat center center  / cover fixed'">
        <div class="slide-overlay post-overlay">
            <div class="post-title">{{title}}</div>
        </div>
    </div>

    <div class="container">
        <div class="post">
            <section>
                <div class="post-description">

                    <div class="col-2 description" v-html="content"></div>
                </div>
            </section>
            <section v-if="acf.assortiment">
                <h3>Перечень услуг</h3>
                <div class="assort-wrapper">

                    <ul class="assortiment">
                        <li v-for="(item, index) in acf.assortiment" :key="index" class="ass-item" @click="openZakaz(item.nazvanie)" :style="' background: url(\''+item.izobrazhenie.sizes.large+'\') no-repeat center center / cover'">

                            <div class="ass-item-title">{{item.nazvanie}}</div>
                            <div class="ass-item-zakaz">Заказать</div>

                        </li>
                    </ul>
                </div>
            </section>

            <section>
                <h3 v-if="pageGallery[0]">Примеры выполненных работ</h3>
                <CoolLightBox :items="pageGallery" :index="index" @close="index = null">
                </CoolLightBox>

                <div class="images-wrapper">
                    <div class="image" v-for="(image, imageIndex) in pageGallery" :key="imageIndex" @click="index = imageIndex" :style="{ background: 'url(' + image + ') no-repeat center center / cover' }">
                        <div class="image-overlay" @click="index = imageIndex">
                            <v-icon>mdi-magnify-plus-outline</v-icon>
                        </div>
                    </div>

                </div>
            </section>
            <Contacts />
        </div>
    </div>
    <transition name="fade">
        <div v-if="popup" class="pop-up">
            <div class="pop-up-wrapper">

                <div @click="popup=false" class="close-btn">+</div>
                <div class="opros-title">
                    Сделайте заказ или отправьте запрос
                </div>
                <div class="opros-descr">
                    Мы свяжемся с вами в течение 5 минут
                </div>
                <div class="flex">
                    <div class="pop-up-holder">

                        <div class="vopros">
                            <div class="number">1</div>
                            <div class="vopros-text">Выберите нужную услугу</div>
                        </div>
                        <v-radio-group v-model="assort">
                            <v-radio v-for="item in acf.assortiment" :key="item" :label="`${item.nazvanie}`" :value="item.nazvanie" @click="openZakaz(item.nazvanie)"></v-radio>
                        </v-radio-group>
                        <div class="vopros" v-if="variants[0]">
                            <div class="number">2</div>
                            <div class="vopros-text">Выберите требуемые параметры</div>
                        </div>

                        <div class="selects" v-for="(variant) in variants" :key="variant.nazv+assort">
                            <v-select @click="clickedSelect = variant.nazv" @change="chgVars" :label="variant.nazv" :items="variant.vars"></v-select>
                        </div>
                        <div class="vopros">
                            <div v-if="!variants[0]" class="number">2</div>
                            <div v-if="variants[0]" class="number">3</div>
                            <div class="vopros-text">Макет (если есть)</div>
                        </div>
                        <v-file-input v-model="file" label="Прикрепить файл"></v-file-input>

                    </div>
                    <div class="pop-up-holder">

                        <div class="vopros">
                            <div v-if="!variants[0]" class="number">3</div>
                            <div v-if="variants[0]" class="number">4</div>
                            <div class="vopros-text">Комментарий к заказу/запросу</div>
                        </div>

                        <v-textarea v-model="comment" name="input-7-1"></v-textarea>
                        <div class="vopros">
                            <div v-if="!variants[0]" class="number">4</div>
                            <div v-if="variants[0]" class="number">5</div>
                            <div class="vopros-text">Как с вами связаться?</div>
                        </div>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field v-model="firstname" :rules="nameRules" :counter="40" label="Ваше имя" required></v-text-field>
                            <v-text-field v-model="phone" :rules="phoneRules" :counter="11" label="Телефон" required></v-text-field>
                            <v-text-field v-model="mail" :rules="mailRules" :counter="30" label="E-mail"></v-text-field>
                            <v-btn :loading=loading :disabled="!valid" class="mr-4" @click="sendData">
                                {{btnText}}
                            </v-btn>
                        </v-form>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Contacts from '../components/Contacts.vue'
import axios from 'axios'
import constants from '../constants'

export default {
    data() {
        return {
            loading: false,
            btnText: 'Отправить',
            vibor: [],
            pageGallery: [],
            index: null,
            popup: false,
            assort: '',
            valid: true,
            firstname: '',
            nameRules: [
                v => !!v || 'Имя обязательно!'
            ],
            mail: '',
            mailRules: [
                v => /.+@.+\..+/.test(v) || 'Укажите верный E-mail',
            ],
            phone: '',
            phoneRules: [
                v => !!v || 'Телефон обязателен!'
            ],
            variants: [],
            clickedSelect: '',
            dataForSend: ``,
            comment: '',
            file: null
        }
    },
    props: {
        id: null,
        title: null,
        content: null,
        acf: null
    },
    components: { CoolLightBox, Contacts },
    methods: {
        sendData() {
            if (this.$refs.form.validate()) {
                this.loading=true
                this.dataForSend += `Имя: ${this.firstname} <br>`
                this.dataForSend += `Телефон: ${this.phone} <br>`
                this.dataForSend += `Почта: ${this.mail} <br>`
                this.dataForSend += `Комментарий: ${this.comment} <br>`
                let data = new FormData;
                data.append('file', this.file)
                data.append('data', this.dataForSend)
                axios.post(constants.mailer, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {console.log(response); this.btnText="Отправлено", this.valid=false, this.loading=false})
            }
        },
        chgVars(variant) {
            this.dataForSend += `${this.clickedSelect} : ${variant} <br>`
        },
        openZakaz(item) {
            let variacii = this.acf.assortiment.find(ass => ass.nazvanie === item);
            this.variants = [];
            this.dataForSend = `
            Заказана услуга <br>
            Тип: ${item} <br>`;
            for (let i = 0; i < variacii.variaczii.length; i++) {
                let vars = variacii.variaczii[i].variacziya.split('<br />\r\n')
                let currentVariant = { "nazv": vars.shift(), "vars": vars }
                this.variants.push(currentVariant)
            }
            this.assort = item;
            this.popup = true;
        },
        mount() {
            document.title = this.title + ' | UVprint'

            let gallery = this.acf.galereya
            for (let i = 0; i < gallery.length; i++) {
                this.pageGallery.push(gallery[i].url)
            }
            this.pageImage = this.glavnoe_izobrazhenie
        }
    },
    created() {
        scrollTo(0, 0)
        this.mount()

    },

}
</script>

<style>
.selects {
    max-width: 320px;
}

.post-sub-image {
    height: 350px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */
    {
    opacity: 0;
}

.theme--light.v-btn.v-btn--has-bg {
    background-color: #8452df;
    color: white;
}

.opros-title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
}

.opros-descr {
    text-align: center;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.pop-up-holder {
    padding: 30px;
}

.close-btn {
    position: absolute;
    right: 20px;
    top: 0px;
    transform: rotateZ(45deg);
    font-size: 50px;
    color: grey;
    cursor: pointer;
    transition: all .3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-btn:hover {
    color: #8452df;
}

.pop-up-wrapper {
    background: white;
    position: relative;
    height: fit-content;

}

.vopros {
    display: flex;
    align-items: center;
}

.vopros .number {
    border-radius: 50%;
    border: 2px solid #8452df;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.pop-up {
    top: 0;
    position: fixed;
    z-index: 9999;
    width: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    max-height: 100vh;
}

.col-2 {
    flex: 1 1 300px;
    margin: 10px;
}

.post-description {
    display: flex;

}

.post {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.post-image {
    height: 400px;
    position: relative;
}

.post-header {
    color: #333333;
    text-align: center;
}

.image .v-icon {
    color: white !important;
    font-size: 60px;
    transition: all .4s;
    transform: scale(0);
}

.image:hover .v-icon {
    transform: scale(1);
}

.images-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.image {
    flex: 1 1 200px;
    height: 230px;
    position: relative;
    margin: 10px;
    max-width: 230px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

}

.image-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all .4s;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 40%), 0px 2px 2px 0px rgb(0 0 0 / 24%), 0px 1px 5px 0px rgb(0 0 0 / 22%);
    will-change: transform;
}

.image-overlay:hover {
    opacity: 1;
}

.assortiment {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ass-item {
    list-style: none;
    flex: 1 1 250px;
    max-width: 23%;
    height: 230px;
    margin: 10px;
    overflow: hidden;

}

.ass-item-price {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #8452df;
    color: white;
    font-weight: 400;
    padding: 12px 10px;
    transition: all .3s;
}

.ass-item-title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    width: 100%;
    font-weight: 500;

}

.ass-item {
    position: relative;
    cursor: pointer;
}

.ass-item-zakaz {
    position: absolute;
    bottom: -60px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #8452df;
    color: white;
    font-weight: 500;
    padding: 12px 10px;
    transition: all .3s;
    will-change: transform;
}

.ass-item:hover .ass-item-zakaz {
    transform: translateY(-60px);
}

.post-title {
    color: white;
    font-weight: 600;
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;

}

.post-overlay {

    margin-top: 85px;

}

.wp-block-columns {
    display: flex;
    flex-wrap: wrap;

}

.wp-block-column {
    padding: 20px;
    flex: 1 1 350px
}

.wp-block-image {
    height: 350px;
}

.wp-block-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.wp-block-column li {
    list-style: circle;
}

.wp-block-column ul {
    margin-left: 30px;
    margin-top: 10px;
}

@media (max-height: 700px) {
    .pop-up {
        align-items: flex-start;
    }
}
</style>
